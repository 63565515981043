import React, { forwardRef, useEffect, useRef, useState } from "react"
import styles from "./Header.module.css"

export const Header = ({
    colorMode,
    updateInputValueHandler,
    color,
    scrollDown,
    forwardedRefs,
    forwardedRef,
}) => {
    let renderCMbutton
    switch (colorMode) {
        case "darkerMode":
            renderCMbutton = (
                <div className={styles.darkModeButtonContainer}>
                    <label
                        onClick={updateInputValueHandler.bind(
                            this,
                            "lightMode"
                        )}
                        className={styles.darkModeText}
                    >
                        light mode.
                    </label>
                </div>
            )

            break

        case "darkMode":
            renderCMbutton = (
                <div className={styles.darkModeButtonContainer}>
                    <label
                        onClick={updateInputValueHandler.bind(
                            this,
                            "darkerMode"
                        )}
                        className={styles.darkModeText}
                    >
                        darker mode.
                    </label>
                </div>
            )
            break
        case "lightMode":
            renderCMbutton = (
                <div className={styles.darkModeButtonContainer}>
                    <label
                        onClick={updateInputValueHandler.bind(this, "darkMode")}
                        className={styles.darkModeText}
                    >
                        dark mode.
                    </label>
                </div>
            )

            break
    }
    function handleScrollDown(ref) {
        scrollDown(ref)
    }
    const delay = 100
    const text = "front-end.dev"
    const [currentText, setCurrentText] = useState("")
    const [currentIndex, setCurrentIndex] = useState(0)
    const [selection, setSelection] = useState(null)
    const ref = useRef(null)
    const handleClick = () => {
        ref.current.focus()
    }
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    })

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            })
        }

        window.addEventListener("resize", handleWindowResize)

        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])

    useEffect(() => {
        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setCurrentText((prevText) => prevText + text[currentIndex])
                setCurrentIndex((prevIndex) => prevIndex + 1)
                // setSelection(currentText.length)
            }, delay)

            return () => clearTimeout(timeout)
        }
    }, [currentIndex, delay, text])

    const [isFocused, setIsFocused] = useState(false)
    useEffect(() => {
        if (document.activeElement === ref.current) {
            setIsFocused(true)
            // console.log("element has focus")
        } else {
            setIsFocused(false)
            // console.log("element does NOT have focus")
        }
    }, [isFocused])

    const handleChange = (event) => {
        setCurrentText(event.target.value)
    }
    // console.log(currentText)
    // useEffect(() => {
    //     setSelection(currentText.length)
    // }, [currentText])

    const handleSelectionChange = (event) => {
        // console.log("******xxxxxxxxxxxxxxxxxxxx********")
        // console.log(event.target.selectionStart)
        // console.log(event.target.selectionEnd)
        // console.log(event)
        setSelection((state) => {
            return event.target.selectionStart
        })
    }
    // console.log("*******textlength*******")
    // console.log(currentText.length)
    // console.log("-----------Selection--------")
    // console.log(selection)
    // console.log(currentText.length)
    const handleKeyDown = (e) => {
        if (
            e.nativeEvent.code === "ArrowUp" ||
            e.nativeEvent.code === "ArrowDown"
        ) {
            e.preventDefault()
        }

        if (
            e.nativeEvent.code === "NumpadEnter" ||
            e.nativeEvent.code === "Enter"
        ) {
            if (currentText.toLowerCase() === "projects") {
                handleScrollDown(forwardedRefs.projectsSection)
            }

            if (currentText.toLowerCase() === "about me") {
                handleScrollDown(forwardedRefs.aboutMeSection)
            }
            if (currentText.toLowerCase() === "contact") {
                handleScrollDown(forwardedRefs.contactSection)
            }

            if (currentText.toLowerCase() === "front-end.dev") {
                window.open(
                    "https://www.linkedin.com/in/ünal-uştuk-312652197/",
                    "_blank"
                )
            }
            if (currentText.toLowerCase() === "linkedin") {
                window.open(
                    "https://www.linkedin.com/in/ünal-uştuk-312652197/",
                    "_blank"
                )
            }

            if (currentText.toLowerCase() === "github") {
                window.open("https://github.com/unalustuk", "_blank")
            }
            if (currentText.toLowerCase() === "exit") {
                window.close()
            }
            if (currentText.toLowerCase() === "quit") {
                window.close()
            }
        }

        // console.log(e)
    }

    const handleAutoFocus = (e) => {
        if (e.type === "click") {
            e.currentTarget.setSelectionRange(
                e.currentTarget.value.length,
                e.currentTarget.value.length
            )
        }
    }

    let constantNumber = 20
    if (windowSize.width < 576) {
        constantNumber = 10
    } else if (windowSize.width < 992) {
        constantNumber = 15
    } else {
        constantNumber = 22.5
    }
    // console.log("------------------constant number------------------")
    // console.log(constantNumber)
    let mg

    if (currentText.length === selection) {
        mg = -constantNumber
    }
    // console.log("****************")
    // console.log(mg)
    // console.log("****************")

    return (
        <header
            style={{
                height: "100vh",
            }}
            ref={forwardedRef}
        >
            <div className={styles.topSection}>
                <label className={styles.text}>ünal uştuk.</label>
            </div>
            <nav className={styles.nav}>
                <ul>
                    <li
                        onClick={() => {
                            handleScrollDown(forwardedRefs.homeSection)
                        }}
                        style={{ color: color.secondary }}
                    >
                        home
                    </li>
                    <li
                        onClick={handleScrollDown.bind(
                            this,
                            forwardedRefs.projectsSection
                        )}
                        style={{ color: color.secondary }}
                    >
                        projects
                    </li>
                    <li
                        onClick={handleScrollDown.bind(
                            this,
                            forwardedRefs.aboutMeSection
                        )}
                        style={{ color: color.secondary }}
                    >
                        about me
                    </li>
                    <li
                        onClick={handleScrollDown.bind(
                            this,
                            forwardedRefs.contactSection
                        )}
                        style={{ color: color.secondary }}
                    >
                        contact
                    </li>
                </ul>
            </nav>
            {renderCMbutton && renderCMbutton}

            <div className={styles.headerMain}>
                {/* <p> &gt;{currentText}</p> */}
                <p>&gt;</p>

                <div className={styles.headerInputs}>
                    <input
                        ref={ref}
                        onSelect={handleSelectionChange}
                        spellCheck="false"
                        autoFocus
                        style={{
                            backgroundColor: color.primary,
                            color: color.secondary,
                            caretColor: color.primary,
                            width: `${currentText.length}ch`,
                            fontVariant: ["tabular-nums"],
                        }}
                        value={currentText}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                        onClick={handleAutoFocus}
                        onFocus={() => setIsFocused(true)}
                        onBlur={() => setIsFocused(false)}
                        maxLength={20}
                    />
                    <label
                        style={{
                            marginLeft: mg,
                            display: !isFocused ? "none" : undefined,
                            borderColor: color.secondary,
                            borderBottomWidth: 1,
                            borderBottom: "solid",
                            alignSelf: "flex-start",
                        }}
                        onClick={handleClick}
                        className={isFocused && styles.blink}
                    ></label>
                </div>
            </div>
        </header>
    )
}
