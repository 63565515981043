import React from "react"
import styles from "./Aboutme.module.css"

export const Aboutme = ({ color, colorMode, forwardedRef }) => {
    return (
        <div className={styles.aboutme} ref={forwardedRef}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 96,
                }}
            >
                <label className={styles.h1}>about me</label>
                <label
                    style={{
                        marginTop: 8,
                        border: "solid",
                        borderBottomWidth: 1,
                        borderColor: color.secondary,
                        width: 96,
                        marginRight: "auto",
                    }}
                ></label>
            </div>

            <div
                className={styles.aboutMeTopSection}
                style={{
                    color: color.secondary,
                }}
            >
                <p
                    style={{
                        fontFamily: "Manrope",
                        fontSize: 30,
                        fontWeight: 400,
                        marginBottom: 12,
                    }}
                >
                    Ünal Uştuk
                </p>
                <p
                    style={{
                        fontFamily: "Manrope",
                        fontSize: 12,
                        fontWeight: 200,
                        lineHeight: 1.5,
                    }}
                >
                    I'm Ünal, a frontend developer proficient in React and React
                    Native. I specialize in crafting dynamic and responsive
                    applications, both for web and mobile platforms. With a
                    passion for creating seamless user experiences, I'm
                    committed to staying ahead in the ever-evolving tech
                    landscape. My focus on detail and creative problem-solving
                    ensures that I deliver high-quality solutions that enhance
                    the overall user experience.
                </p>
            </div>

            <div style={{}} className={styles.aboutMidSection}>
                <div
                    style={{
                        backgroundColor:
                            colorMode === "lightMode"
                                ? color.primary
                                : color.secondary,
                        boxShadow: 2,
                    }}
                    className={styles.elementContainer}
                >
                    <div className={styles.iconContainer}>
                        <i
                            className="fa-solid fa-laptop-code fa-2xl"
                            style={{
                                color:
                                    colorMode === "lightMode"
                                        ? color.secondary
                                        : color.primary,
                            }}
                        ></i>
                    </div>
                    <div
                        className={styles.elementTextContainer}
                        style={{
                            color:
                                colorMode === "lightMode"
                                    ? color.secondary
                                    : color.primary,
                        }}
                    >
                        <p className={styles.elementH1}>React</p>
                        <p className={styles.elementText}>
                            Build SPA's & Websites
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor:
                            colorMode === "lightMode"
                                ? color.primary
                                : color.secondary,
                        boxShadow: 2,
                    }}
                    className={styles.elementContainer}
                >
                    <div className={styles.iconContainer}>
                        <i
                            className="fa-solid fa-mobile-screen fa-2xl "
                            style={{
                                color:
                                    colorMode === "lightMode"
                                        ? color.secondary
                                        : color.primary,
                            }}
                        ></i>
                    </div>
                    <div
                        className={styles.elementTextContainer}
                        style={{
                            color:
                                colorMode === "lightMode"
                                    ? color.secondary
                                    : color.primary,
                        }}
                    >
                        <p className={styles.elementH1}>React Native</p>
                        <p className={styles.elementText}>
                            Mobile Application Development
                        </p>
                    </div>
                </div>
                <div
                    style={{
                        backgroundColor:
                            colorMode === "lightMode"
                                ? color.primary
                                : color.secondary,
                        boxShadow: 2,
                    }}
                    className={styles.elementContainer}
                >
                    <div className={styles.iconContainer}>
                        <i
                            className="fa-regular fa-file-code fa-2xl"
                            style={{
                                color:
                                    colorMode === "lightMode"
                                        ? color.secondary
                                        : color.primary,
                            }}
                        ></i>
                    </div>
                    <div
                        className={styles.elementTextContainer}
                        style={{
                            color:
                                colorMode === "lightMode"
                                    ? color.secondary
                                    : color.primary,
                        }}
                    >
                        <p className={styles.elementH1}>Web Development</p>
                        <p className={styles.elementText}>
                            Responsive Web Design
                        </p>
                    </div>
                </div>

                {/* <i
                    className="fa-solid fa-code fa-2xl"
                    style={{
                        color: "#000000",
                    }}
                ></i> */}

                {/*
                 */}
            </div>
            {/* <div className={styles.aboutBottomSection}>
                <div className={styles.aboutBottomElement}>
                    <div
                        style={{
                            borderLeft: "solid",
                            borderLeftWidth: 2,
                            color: "#00000040",
                            paddingLeft: 24,
                            paddingRight: 24,

                            paddingTop: 12,
                            paddingBottom: 12,
                        }}
                    >
                        <p className={styles.aboutBottomListHeader}>
                            I have years of experience with
                        </p>
                        <ul className={styles.aboutBottomList}>
                            <li className={styles.aboutBottomListItem}>
                                React
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                React Native
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                Javascript
                            </li>
                            <li className={styles.aboutBottomListItem}>CSS</li>
                            <li className={styles.aboutBottomListItem}>HTML</li>
                            <li className={styles.aboutBottomListItem}>
                                APIs Integration
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                Redux
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.aboutBottomElement}>
                    <div
                        style={{
                            borderLeft: "solid",
                            borderLeftWidth: 2,
                            color: "#00000040",
                            paddingLeft: 24,
                            paddingRight: 24,

                            paddingTop: 12,
                            paddingBottom: 12,
                        }}
                    >
                        <p className={styles.aboutBottomListHeader}>
                            I have experience with
                        </p>
                        <ul className={styles.aboutBottomList}>
                            <li className={styles.aboutBottomListItem}>
                                
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={styles.aboutBottomElement}>
                    <div
                        style={{
                            borderLeft: "solid",
                            borderLeftWidth: 2,
                            color: "#00000040",
                            paddingLeft: 24,
                            paddingRight: 24,

                            paddingTop: 12,
                            paddingBottom: 12,
                        }}
                    >
                        <p className={styles.aboutBottomListHeader}>
                            I have years of experience with
                        </p>
                        <ul className={styles.aboutBottomList}>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                            <li className={styles.aboutBottomListItem}>
                                sdadsa
                            </li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
