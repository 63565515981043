import projectImage1 from "../assets/images/projects1.png"
import projectImage2 from "../assets/images/mobileapp.png"
import projectImage3 from "../assets/images/project2.png"

export const projects = [
    {
        image: projectImage1,
        header1: "Landing Page",
        header2: "Web Project",
        infoText:
            "Built using React, landing page is all about practical design and smooth functionality. Experience a responsive design that just works, thanks to React's efficiency. Dive into a dynamic platform that grows with you, keeping it simple for modern web development.",
        tech: ["React", "Javascript", "CSS", "APIs"],
        githubLink: "",
        liveLink: "https://calculusmuhendislik.com",
    },
    {
        image: projectImage2,
        header1: "Alfa Smart Car Systems",
        header2: "Mobile Application",
        infoText:
            "Developed a fleet management app with React Native, ensuring smooth tracking through real-time location data and optimized routes. The app, available on the App Store and Play Store, streamlines operations with integrated geolocation APIs for automated alerts.",
        tech: ["React Native", "Expo", "iOS", "Android"],
        githubLink: "",
        liveLink:
            "https://play.google.com/store/apps/details?id=com.dukateknoloji.alfasmartmobile",
    },

    {
        image: projectImage3,
        header1: "Theatre Search App",
        header2: "Web Project",
        infoText:
            "Discover theatres effortlessly with our React-powered search application. Enjoy a sleek, intuitive interface and responsive design that works seamlessly on any device. Built with React, our platform offers dynamic functionality and scalability, making it easy to find the perfect performance venue. Dive into a world of theatre with a tool designed for modern needs..",
        tech: ["React", "Javascript", "CSS", "APIs"],
        githubLink: "",
        liveLink: "",
    },
]
