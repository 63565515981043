import { useEffect, useRef, useState } from "react"
import "./App.css"
import {
    colorsDarkerMode,
    colorsLightMode,
    colorsDarkMode,
} from "./constants/colors"
import { Aboutme } from "./screens/Aboutme/Aboutme"
import { Contact } from "./screens/Contact/Contact"
import { Footer } from "./screens/Footer/Footer"
import { Header } from "./screens/Header/Header"
import { Projects } from "./screens/Projects/Projects"

function App() {
    const [colorMode, setColorMode] = useState("darkerMode")
    const [color, setColor] = useState(colorsDarkerMode)

    const homeSection = useRef(null)
    const projectsSection = useRef(null)
    const aboutMeSection = useRef(null)
    const contactSection = useRef(null)

    const refs = {
        homeSection: homeSection,
        projectsSection: projectsSection,
        aboutMeSection: aboutMeSection,
        contactSection: contactSection,
    }

    function scrollDown(ref) {
        window.scrollTo({
            top: ref.current.offsetTop,
            behavior: "smooth",
        })
    }

    function updateInputValueHandler(enteredValue) {
        switch (enteredValue) {
            case "darkerMode":
                setColorMode("darkerMode")
                break

            case "darkMode":
                setColorMode("darkMode")
                break
            case "lightMode":
                setColorMode("lightMode")

                break
        }
    }

    useEffect(() => {
        switch (colorMode) {
            case "darkerMode":
                setColor(colorsDarkerMode)
                break

            case "darkMode":
                setColor(colorsDarkMode)

                break
            case "lightMode":
                setColor(colorsLightMode)

                break
        }
    }, [colorMode])

    return (
        <div
            style={{
                color: color.secondary,
                backgroundColor: color.primary,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
            className="App"
        >
            <div
                style={{
                    maxWidth: 1440,
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <Header
                    scrollDown={scrollDown}
                    forwardedRef={homeSection}
                    forwardedRefs={refs}
                    updateInputValueHandler={updateInputValueHandler}
                    colorMode={colorMode}
                    color={color}
                />
                <Projects
                    forwardedRef={projectsSection}
                    colors={colorsDarkerMode}
                    color={color}
                />
                <Aboutme
                    forwardedRef={aboutMeSection}
                    colors={colorsDarkerMode}
                    colorMode={colorMode}
                    color={color}
                />
                <Contact
                    forwardedRef={contactSection}
                    colors={colorsDarkerMode}
                    color={color}
                />
                <Footer scrollDown={scrollDown} forwardedRefs={refs} />
            </div>
        </div>
    )
}

export default App
