export const colorsLightMode = {
    primary: "#ffffff",
    secondary: "#000000",
    gray: "#f6f6f6",
}

export const colorsDarkMode = {
    primary: "#2b2c2e",
    secondary: "#ffffff",
    gray: "#f6f6f6",
}

export const colorsDarkerMode = {
    primary: "#000000",
    secondary: "#ffffff",
    gray: "#f6f6f6",
}
