import React from "react"
import styles from "./ProjectItem.module.css"

export const ProjectItem = ({ colors, project }) => {
    return (
        <div className={styles.main}>
            <div
                className={styles.imageSection}
                style={{ backgroundColor: "#fafafa" }}
            >
                <img className={styles.image} src={project.image} />
            </div>
            <div className={styles.infoSection}>
                <div className={styles.infoTopContainer}>
                    <p className={styles.infoH1}>{project.header1}</p>
                    <p className={styles.infoH2}>{project.header2}</p>
                </div>
                <p className={styles.infoText}>{project.infoText}</p>
                <div
                    style={{
                        marginTop: "auto",
                    }}
                >
                    <div className={styles.tech}>
                        {project.tech.map((tech, i) => {
                            let dot
                            console.log(project.tech.length)
                            console.log(i)
                            if (project.tech.length - 1 !== i) {
                                dot = ","
                            }
                            return (
                                <label
                                    style={{
                                        color: colors.secondary,
                                    }}
                                    className={styles.techElement}
                                >
                                    {tech}
                                    {dot}
                                </label>
                            )
                        })}
                    </div>
                    <div className={styles.linkContainer}>
                        {project.githubLink && (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}
                            >
                                <a href={project.githubLink} target="_blank">
                                    github
                                </a>
                            </div>
                        )}
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                marginLeft: "auto",
                            }}
                        >
                            <a href={project.liveLink} target="_blank">
                                see live
                            </a>
                            <label
                                style={{
                                    marginTop: 4,
                                    borderBottom: "solid",
                                    borderBottomWidth: 3,
                                    borderColor: colors.secondary,
                                    width: 24,
                                    marginLeft: "auto",
                                }}
                            ></label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
