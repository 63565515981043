import React from "react"
import styles from "./Projects.module.css"
import { ProjectItem } from "../../components/Projects/ProjectItem"
import { projects } from "../../json/projects"
export const Projects = ({ color, forwardedRef }) => {
    return (
        <section className={styles.projects} ref={forwardedRef}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 96,
                }}
            >
                <label className={styles.h1}>projects</label>
                <label
                    style={{
                        marginTop: 8,
                        border: "solid",
                        borderBottomWidth: 1,
                        borderColor: color.secondary,
                        width: 96,
                        marginLeft: "auto",
                    }}
                ></label>
            </div>
            <div className={styles.main}>
                {projects.map((project) => {
                    return (
                        <ProjectItem
                            colors={color}
                            project={project}
                        ></ProjectItem>
                    )
                })}
            </div>
        </section>
    )
}
