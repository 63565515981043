import React from "react"
import styles from "./Contact.module.css"
import { ReactComponent as Fi } from "../../assets/icons/jam_fiverr.svg"

export const Contact = ({ color, forwardedRef }) => {
    return (
        <div className={styles.contact} ref={forwardedRef}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 96,
                }}
            >
                <label className={styles.h1}>contact me</label>
                <label
                    style={{
                        marginTop: 8,
                        border: "solid",
                        borderBottomWidth: 1,
                        borderColor: color.secondary,
                        width: 96,
                        marginRight: "auto",
                        marginLeft: "auto",
                    }}
                ></label>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: 100,
                }}
            >
                <div
                    style={{
                        marginRight: 16,
                        marginLeft: 16,
                    }}
                >
                    <a
                        href="https://www.linkedin.com/in/ünal-uştuk-312652197/"
                        target="_blank"
                    >
                        <i
                            className="fa-brands fa-linkedin fa-2xl"
                            style={{
                                color: color.secondary,
                            }}
                        ></i>
                    </a>
                </div>
                <div
                    style={{
                        marginRight: 16,
                        marginLeft: 16,
                    }}
                >
                    <a href="https://github.com/unalustuk" target="_blank">
                        <i
                            className="fa-brands fa-github fa-2xl"
                            style={{
                                color: color.secondary,
                            }}
                        ></i>
                    </a>
                </div>
                <div
                    style={{
                        marginRight: 16,
                        marginLeft: 16,
                    }}
                >
                    <a href="mailto:unalustk@gmail.com">
                        <i
                            className="fa-solid fa-envelope fa-2xl"
                            style={{
                                color: color.secondary,
                            }}
                        ></i>
                    </a>
                </div>
                <div
                    style={{
                        marginRight: 16,
                        marginLeft: 16,
                    }}
                >
                    <a href="https://www.fiverr.com/ustk_software">
                        <Fi fill={color.secondary} />
                    </a>
                </div>
            </div>
        </div>
    )
}
