import React from "react"
import styles from "./Footer.module.css"

export const Footer = ({ scrollDown, forwardedRefs }) => {
    function handleScrollDown(ref) {
        scrollDown(ref)
    }
    return (
        <footer className={styles.footer}>
            <p style={{ marginBottom: 12 }} className={styles.footerText}>
                you reached bottom!
            </p>
            <label
                onClick={() => {
                    handleScrollDown(forwardedRefs.homeSection)
                }}
                className={styles.footerText}
            >
                &gt;start again.
            </label>
        </footer>
    )
}
